


.TopicSelector {
    border: 1px solid #ddd;
    padding: 3px 10px 5px;
    border-radius: 20px;
    cursor: pointer;
    margin: -3px 0 0 5px;
    transition: all 0.1s ease;
}

.TopicSelector:hover {
    border: 1px solid #000;
    color: white;
    background: black;
}


