.ant-picker-ranges {
    margin-bottom: 0;
    padding: 4px 12px;
    overflow: hidden;
    line-height: 34px;
    text-align: left;
    list-style: none;
}

.ant-picker-footer .ant-picker-ranges {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    margin: 0;
}

.ant-picker-footer .ant-picker-ranges li {
    margin: 5px 0;
}

.ant-picker-footer .ant-picker-ranges li:before {
    content: none;
}

.ant-picker-ranges > li {
    display: inline-block;
}

.ant-picker-now {
    text-align: left;
}

.ant-picker-ranges .ant-picker-ok {
    float: right;
    margin-left: 8px;
}

